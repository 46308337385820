/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const findByPropReccursively = (obj, prop, val) => {
  if (obj == null) return null
  if (obj[prop] === val) {
    return obj
  }
  let result
  for (const o in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, o) && typeof obj[o] === 'object') {
      result = findByPropReccursively(obj[o], prop, val)
      if (result) {
        return result
      }
    }
  }
  return result
}

export const uniq = (value, index, self) => {
  return self.indexOf(value) === index
}

export const empty = value => {
  return value === undefined || value?.length === 0 || value === '' || value === false || value === null || value === {}
}

export const removeAccent = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
