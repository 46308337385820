import Vue from 'vue'
import Vuei18n from 'vue-i18n'
import ar from './languages/ar.json'
import de from './languages/de.json'
import en from './languages/en.json'
import es from './languages/es.json'
import fr from './languages/fr.json'
import it from './languages/it.json'
import ja from './languages/ja.json'
import ko from './languages/ko.json'
import nl from './languages/nl.json'
import pl from './languages/pl.json'
import tr from './languages/tr.json'
import zh from './languages/zh.json'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    ar,
    de,
    en,
    es,
    fr,
    it,
    ja,
    ko,
    nl,
    pl,
    tr,
    zh,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
})

export default i18n
