/**
 * Load GTM script tag
 * @param {String}  id  GTM ID
 */
export const loadGTMScript = id => {
  if (isGTMReady() === false || !id) {
    return
  }

  const dl = 'dataLayer'
  const script = document.createElement('script')

  window[dl] = window[dl] || []

  window[dl].push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  })

  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`

  document.body.appendChild(script)
}

export const launchGAPageView = pagePath => {
  if (isGTMReady() === false || isGAReady() === false) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'GI_Pageview',
    pagePath,
  })
}

export const launchGAEvent = (eventCategory, eventAction, eventLabel) => {
  if (isGTMReady() === false || isGAReady() === false) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'GI_Event',
    eventCategory,
    eventAction,
    eventLabel,
  })
}

function isGTMReady() {
  if (!window) {
    return false
  }
  return true
}

function isGAReady() {
  const cookieConsent = localStorage.getItem('gi-cookie-consent')
  if (cookieConsent !== null) {
    return JSON.parse(cookieConsent)
  }

  return true
}

export const initClientUA = key => {
  window.uaKey = key
}

export const initGtrUA = key => {
  window.uaGtrKey = key
}
