import Vue from 'vue'
import VueRouter from 'vue-router'

import { launchGAPageView } from '@/utils/gtm.utils'

import { INSTANCE_UUID } from '@/config/api.config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: ':code',
        name: 'Landing',
        component: () => import('@/views/Landing.vue'),
      },
      {
        path: ':code/feedback',
        name: 'Feedback',
        component: () => import('@/views/Feedback.vue'),
      },
      {
        path: ':code/result',
        name: 'Result',
        component: () => import('@/views/Result.vue'),
      },
      {
        path: ':code/no-game',
        name: 'NoGame',
        component: () => import('@/views/NoGame.vue'),
      },
      {
        path: 'static/:page',
        name: 'Static',
        component: () => import('@/views/Static.vue'),
      },
      {
        path: '/',
        name: 'BrandPage',
        redirect: () => {
          if (INSTANCE_UUID === 'd731d614-1d3d-11ec-9621-0242ac130002') {
            window.location.href = 'https://www.dynastar.com/'
          }
          if (INSTANCE_UUID === '749b72ff-2fe0-4349-baec-128ba6d64d94') {
            window.location.href = 'https://www.lange-boots.com/fr/'
          }
          if (INSTANCE_UUID === 'cd7056e6-1d3d-11ec-9621-0242ac130002') {
            window.location.href = 'https://www.rossignol.com/'
          }
        },
      },
      { path: '*', name: 'Error', component: () => import('@/views/Error.vue') },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to, from) => {
  if (to.fullPath !== from.fullPath || to.name !== from.name) {
    launchGAPageView(to.fullPath)
  }
})

export default router
