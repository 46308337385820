import {
  getApiConfig,
  getApiTheme,
  getApiLanding,
  getApiGame,
  putApiResult,
  getApiStatic,
  getApiUserInfo,
  getApiIpInfo,
  postApiConnect,
  postApiBalancer,
  postApiIncrement,
} from '@/services/global.service'
import { defaultTheme } from '@/config/theme.config'
import { mergeDeep } from '@/utils/object.util'
import $i18n from '@/i18n/i18n'

const SET_CONFIG = 'SET_CONFIG'
const SET_THEME = 'SET_THEME'
const SET_LOCALE = 'SET_LOCALE'
const SET_LANDING = 'SET_LANDING'
const SET_GAME = 'SET_GAME'
const SET_RESULT = 'SET_RESULT'
const SET_STATIC = 'SET_STATIC'
const SET_USER_IP = 'SET_USER_IP'
const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT'
const SET_CONNECT = 'SET_CONNECT'
const SET_BALANCER = 'SET_BALANCER'

const state = () => {
  return {
    config: null,
    theme: null,
    currentLocale: '',
    landing: null,
    balancer: null,
    game: null,
    result: null,
    static: null,
    connect: null,
    user: {
      ip: '',
      country: '',
    },
    cookieConsent: localStorage.getItem('gi-cookie-consent'),
  }
}

const mutations = {
  [SET_CONFIG]: (state, config) => {
    state.config = config
  },
  [SET_THEME]: (state, theme) => {
    state.theme = theme
  },
  [SET_LOCALE]: (state, locale) => {
    state.currentLocale = locale
    $i18n.locale = locale.substr(0, 2)
  },
  [SET_LANDING]: (state, landing) => {
    state.landing = landing
  },
  [SET_GAME]: (state, game) => {
    state.game = game
  },
  [SET_RESULT]: (state, result) => {
    state.result = result
  },
  [SET_STATIC]: (state, data) => {
    state.static = data
  },
  [SET_USER_IP]: (state, ip) => {
    state.user.ip = ip
  },
  [SET_USER_COUNTRY]: (state, country) => {
    state.user.country = country
  },
  [SET_COOKIE_CONSENT]: (state, consent) => {
    state.cookieConsent = consent
  },
  [SET_CONNECT]: (state, connect) => {
    state.connect = connect
  },
  [SET_BALANCER]: (state, balancer) => {
    state.balancer = balancer
  },
}

const actions = {
  async getConfig({ commit }) {
    const data = await getApiConfig()
    commit(SET_CONFIG, data)
  },
  async getTheme({ commit }) {
    const data = await getApiTheme()
    commit(SET_THEME, mergeDeep(defaultTheme, data))
  },
  async getLanding({ commit }, code) {
    const data = await getApiLanding(code)
    commit(SET_LANDING, { ...data, code })
  },
  async getBalancer({ commit }, payload) {
    const { data } = await postApiBalancer(payload)
    commit(SET_BALANCER, data)
    if (data.connect) {
      commit(SET_CONNECT, data.connect)
    }
  },
  async getConnect({ commit }, payload) {
    const { data } = await postApiConnect(payload)
    commit(SET_CONNECT, data)
  },
  async getGame({ state, commit }) {
    const game = await getApiGame(state.connect.scenario)
    commit(SET_GAME, game)
  },
  async getResult({ commit }, feedback) {
    const { data } = await putApiResult(feedback)
    commit(SET_RESULT, data)
  },
  async getStatic({ commit }) {
    const data = await getApiStatic()
    commit(SET_STATIC, data)
  },
  async getUserInfo({ commit }) {
    const { ip } = await getApiIpInfo()
    commit(SET_USER_IP, ip)
    // In dev (127.0.x.x) we got a bogon ip see https://ipinfo.io/developers/data-types#geolocation-data
    const { country } = await getApiUserInfo(ip)
    commit(SET_USER_COUNTRY, country)
  },
  async incrementStat({ state }, statType) {
    await postApiIncrement({
      brandId: state.landing.brandId,
      urlSuffix: state.landing.code,
      statType,
    })
  },
  setCurrentLocale({ commit, state }, browserLocale) {
    const fullIsoLocale = `${browserLocale}-${browserLocale}`
    if (state.config.locales.includes(browserLocale)) {
      commit(SET_LOCALE, browserLocale)
    } else if (state.config.locales.includes(fullIsoLocale)) {
      commit(SET_LOCALE, fullIsoLocale)
    } else {
      commit(SET_LOCALE, state.config.defaultLocale)
    }
  },
  updateCurrentLocale({ commit }, locale) {
    commit(SET_LOCALE, locale)
  },
  setCookieConsent({ commit }, consent) {
    commit(SET_COOKIE_CONSENT, consent)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
