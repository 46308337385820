export const LOCAL_PREFIX = '/data'
export const API_PREFIX = '/api'
export const GAME_PREFIX = '/game'
export const API_PREFIX_TOUCHPOINTS = '/api-node-touchpoint'

export const EMAIL_VERIFY = 'https://utils-functions.netlify.app/api/emailVerify?token=2D9BA797572C8D1BE13ECC31B37B2'

export const IP_INFO_TOKEN = '520fa415d9bb17'

export const INSTANCE_UUID = process.env.INSTANCE_UUID || 'dda2db4c-1d3d-11ec-9621-0242ac130002'
