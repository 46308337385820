export const defaultTheme = Object.freeze({
  global: {
    radius: '10px',
    font: 'Poppins',
  },
  header: {
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  footer: {
    background: '#282840',
    color: '#fff',
  },
  landing: {
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  starter: {
    dark: true,
    background: '#282840',
    color: '#fff',
  },
  game: {
    dark: true,
    background: '#212553',
    color: '#fff',
    stepper: {
      background: '#E4E7ED',
      progress: '#EF3D6A',
    },
  },
  cta: {
    radius: '4px',
    primary: {
      background: '#EF3D6A',
      color: '#fff',
    },
    secondary: {
      color: '#fff',
    },
    tertiary: {
      color: '#333',
    },
  },
  title: {
    primary: {
      font: 'Poppins',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      font: 'Poppins',
      color: '#EE212B',
    },
    tertiary: {
      font: 'Poppins',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  input: {
    radius: '4px',
    default: {
      color: '#333',
      background: '#fff',
    },
    active: {
      color: '#fff',
      background: '#397FF5',
    },
  },
  review: {
    color: '#EE212B',
  },
  static: {
    background: '#fff',
    color: '#333',
    link: {
      color: '#333',
    },
  },
  link: {
    color: '#fff',
  },
  cookie: {
    background: '#fff',
    dark: false,
    color: '#333',
  },
})
