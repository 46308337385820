<template>
  <router-view />
</template>

<script>
import { loadGTMScript } from '@/utils/gtm.utils'
import { GTM_KEY } from '@/config/google-keys.config'

export default {
  name: 'App',
  mounted() {
    loadGTMScript(GTM_KEY)
  },
}
</script>

<style lang="scss">
@tailwind base; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind components; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind utilities; /* stylelint-disable-line scss/at-rule-no-unknown */

#app {
  height: 100%;
}
</style>
