import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      minifyTheme,
    },
  },
  breakpoint: {
    thresholds: {
      xs: 768,
      sm: 1024,
      md: 1280,
      lg: 1920,
    },
    mobileBreakpoint: 'sm',
  },
})
