import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/services/api.service'
import {
  EMAIL_VERIFY,
  IP_INFO_TOKEN,
  INSTANCE_UUID,
  API_PREFIX,
  LOCAL_PREFIX,
  GAME_PREFIX,
  API_PREFIX_TOUCHPOINTS,
} from '@/config/api.config'

export const getApiConfig = () => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/config.json`)
  else return sendGetRequest(`${API_PREFIX}/config/${INSTANCE_UUID}`)
}

export const getApiTheme = () => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/theme.json`)
  else return sendGetRequest(`${API_PREFIX}/theme/${INSTANCE_UUID}`)
}

export const getApiLanding = code => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/landing.old.json`)
  else return sendGetRequest(`${API_PREFIX}/landing/${INSTANCE_UUID}/${code}`)
}

export const getApiGame = scenario => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/game.old.json`)
  else return sendGetRequest(`${API_PREFIX}/game/${INSTANCE_UUID}/${scenario}`)
}

export const getApiStatic = () => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/static.json`)
  else return sendGetRequest(`${API_PREFIX}/static/${INSTANCE_UUID}`)
}

export const checkApiEmail = email => {
  return sendGetRequest(`${EMAIL_VERIFY}&email=${email}`)
}

export const getApiIpInfo = () => {
  return sendGetRequest(`/ipify?format=json`)
}

export const getApiUserInfo = ip => {
  return sendGetRequest(`/ipinfo/${ip}?token=${IP_INFO_TOKEN}`)
}

export const putApiResult = data => {
  if (process.env.NODE_ENV === 'development') return sendGetRequest(`${LOCAL_PREFIX}/result.json`)
  else return sendPutRequest(`${GAME_PREFIX}/feedback.php`, data)
}

export const postApiBalancer = data => {
  return sendPostRequest(`${GAME_PREFIX}/balancer.php`, data)
}

export const postApiConnect = data => {
  return sendPostRequest(`${GAME_PREFIX}/connect.php`, data)
}

export const postApiIncrement = data => {
  return sendPostRequest(`${API_PREFIX_TOUCHPOINTS}/incrementTouchpointStat`, data, {
    headers: {
      authorization: process.env.API_NODE_TOUCHPOINT_WRITE_TOKEN || '8D56368CA758B8B23EEF6A442AA92',
    },
  })
}
